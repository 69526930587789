import * as actions from "../actions/scroll"

const initialState = {
    fromTop: typeof window !== 'undefined' ? window.scrollY : 0,
}

const scrollReducer = (state=initialState, action) => {

    let change = {}

    switch (action.type) {
        case actions.FROM_TOP :
            change.fromTop = action.fromTop
        break
    }

    return {...state, ...change}
}

export default scrollReducer
