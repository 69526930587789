import React from 'react'
import {Box} from 'theme-ui'

const AspectRatio = ({
    children,
    ratio=4/3,
    ...rest
}) => {

    return (
        <Box
            sx={{
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
            }}>
            <Box
                sx={{
                    width: '100%',
                    height: 0,
                    paddingBottom: Array.isArray(ratio)
                        ? ratio.map(r => 100 / r + '%')
                        : 100 / ratio + '%',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                }}
                {...rest}>
                {children}
            </Box>
        </Box>
    )
}

export default AspectRatio
