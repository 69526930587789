/** @jsx jsx */
import {jsx, Heading} from "theme-ui"

const ContainerHeading = ({
    children,
}) =>
    <Heading
        variant="caps"
        as="h5"
        sx={{
            mb: [6, 8],
            color: 'heading',
        }}>
        {children}
    </Heading>

export default ContainerHeading
