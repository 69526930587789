import React from "react"
// import { Link, graphql } from "gatsby"
// import styled, {css} from "styled-components"
// import theme from "../theme"
// import projects from "../data/projects"
// import clients from "../data/clients"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import {Flex, Box} from "../components/flexbox"
// import {Text, Heading} from "../components/text"
// import {Timeline, Milestone} from "../components/timelines/horizontal-timeline"
// import Recommendations from "../components/recommendations"
// import Button from "../components/button"
// import Hero from "../components/hero"
// import Article from "../components/article"
// import Section from "../components/section"
// import Breadcrumbs from "../components/breadcrumbs"
// import {truncate} from "../components/mixins"
import IndexHero from "../components/index-hero"
import IndexServices from "../components/index-services"
import IndexProjects from "../components/index-projects"
import IndexRecommendations from "../components/index-recommendations"
import IndexProfile from "../components/index-profile"

// const Before = () =>
//     <>
//         <Box pt={'10px'} />
//         <Breadcrumbs
//             // position="sticky"
//             // top="40px"
//             // left="0"
//             display={{_: 'none', lg: 'flex'}}
//             pb={4}
//             label="/ Profile"
//         />
//     </>

const IndexPage = () =>
    <Layout>
        <SEO title="Web Development Services" />

        <IndexHero />
        <IndexProjects />
        <IndexRecommendations />
        <IndexServices />
        <IndexProfile />

        {/* <Section before={<Before />} after=''>
            <Article>
                <Heading as="h1">
                    Software development services for progressive brands and agencies looking to establish quality apps and websites.
                    Software Development Services For Progressive Brands &amp; Agencies
                </Heading>
                <p>
                    Hi, I'm Marc and this is my site. I'm a web engineer specializing in modern JavaScript and PHP.
                    I focus on serving ambitious brands and agencies in the production of quality apps and websites.
                </p>
                <p>
                    I'm currently available for contract work.
                </p>
                <Button as={Link} to="/contact" variant="darkHero">Lets Connect</Button>
            </Article>
        </Section> */}

        {/* <Recommendations /> */}

        {/* independent consultant / engineer */}
        {/* With over 10 years of experience, I'm well versed in many back and front-end environments and am
        frequently investigating new trends and best practice approaches to the ever changing landscape
        of web and app development. */}
        {/* <h1>Hi, I'm Marc, an independent web developer with a focus on modern <a>JavaScript</a> and <a>PHP</a>.</h1> */}
        {/* <h1>Hi, I'm Marc, an independent web developer specializing in modern <a>JavaScript</a> and <a>PHP</a>.</h1> */}
        {/* <h1>Hi, I'm Marc, an independent web developer specializing in modern <a>JavaScript</a> and <a>PHP</a> for back and front-end projects.</h1> */}
        {/* <p>I concentrate on helping brands and agencies develop tools that help their products and services engage and connect with their audience.</p> */}
        {/* <p>I offer my availablity on an hourly, ongoing basis to brands and agencies looking to develop tools that help their products and services engage and connect with their users.</p> */}
        {/* <p>I offer my availablity on an hourly, ongoing basis to brands and agencies that are looking to extend their team's development capabilities, short or long term.</p> */}
        {/* <p>I'm offering my availablity on an hourly, ongoing basis to progressive brands and agencies, short or long term. My full portfolio and work history available upon request.</p> */}
        {/* <p>I'm available for hire on an hourly, ongoing basis to progressive brands and agencies looking to extend their team's capabilities.</p> */}
        {/* <p>I'm available for hire on an hourly, ongoing basis to progressive brands and agencies looking to build and maintain back and front-end projects.</p> */}
        {/*software developer*/}
        {/* I'm offering my availability on an hourly, ongoing basis  */}
        {/* who are determined */}
        {/* I offer my availability to progressive brands and agencies committed to establishing quality apps and websites. */}
        {/* With over 10 years of experience, I'm well versed in many back-end and front-end environments and am
        frequently investigating new trends and best practice approaches to the ever changing landscape
        of web and app development. */}
        {/* computer programming. */}
        {/* to maintain my place at the cutting-edge of my profession. */}
        {/* <br/> */}
        {/* <p>Interseted in extending your team's development capabilities?</p> */}
        {/* <p>Full portfolio and work history available upon request.</p> */}
        {/* <p>Simply <a href="javascript:;">get in touch</a> and let's get things moving.</p> */}

        {/* <Flex width={1} flexDirection="column" alignItems="center" justifyContent="center" px={4} py={2}>
            <Heading as="h4" fontSize={6} mb={6}>Open Source Projects</Heading>
            <Flex maxWidth="tightest" flexWrap="wrap" justifyContent="center">
                {projects.map((project, i) => (
                    <Box key={project.title + i} px={4} py={1} m={1} border={`1px solid ${theme.colors.shades[2]}`} borderRadius={4}>
                        <Heading textAlign="center">
                            <a css={{whiteSpace: 'nowrap'}} href={project.url} target="_blank">{project.title}</a>
                            <br />
                            <span>{project.tags[0]}</span>
                        </Heading>
                    </Box>
                ))}
            </Flex>
        </Flex> */}

        {/* <Flex width={1} flexDirection="column" alignItems="center" justifyContent="center" px={0} py={5}>
            <Heading as="h4" fontSize={6} mb={8}>Featured Works</Heading>
            <Timeline>
                <Box>
                    <Box height="1px" width="200px" />
                </Box>
                {clients.map((client, i) => (
                    <Milestone weight={client.weight} pr={i !== clients.length - 1 ? '60px' : '0'}>
                        <ClientHeading>
                            <a href={client.url || '#'} target="_blank" rel="nofollow noreferrer noopener">
                                {client.title}
                            </a>
                        </ClientHeading>
                        <Text>{client.year}</Text>
                    </Milestone>
                ))}
                <Box>
                    <Box height="1px" width="120px" />
                </Box>
            </Timeline>
        </Flex> */}

        {/* <Timeline>
            {clients.map((client, i) => {
                let reverse = i % 2 ? true : false
                let content =
                    <Heading as="h4">
                        <a href="{client.url || '#'}" target="_blank" rel="nofollow noreferrer noopener">
                            {client.title}
                            <p>{reverse && 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod ultricies lacus. Quisque leo massa, vulputate vitae vehicula id, malesuada sit amet erat. Morbi at finibus libero. Curabitur viverra odio nisi. Praesent egestas sapien eget lectus feugiat tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod ultricies lacus. Quisque leo massa, vulputate vitae vehicula id, malesuada sit amet erat. Morbi at finibus libero. Curabitur viverra odio nisi. Praesent egestas sapien eget lectus feugiat tempor. '}</p>
                        </a>
                    </Heading>
                return (
                    <Milestone
                        contentBefore={reverse && content}
                        contentAfter={! reverse && content}
                        // contentOffset="40%"
                        // lineLengthAbove="0" lineLengthBelow="calc(50% - 14px)" lineOffsetBelow="14px"
                    />
                )
            })}
        </Timeline> */}

        {/* <Box px={4} py={8}>
            <Text as="p" textAlign="center" fontSize="6">
                I'm currently accepting new clients for my schedule in 2020. Get in touch to secure your spot.
                Full portfolio available for interested brands and agencies looking to hire. Get in touch to get things moving.
                <br />
                <a href="javascript:;">Get in touch</a>
            </Text>
        </Box> */}

        {/* <FontAwesomeIcon
            icon={`faGithub`}
            css={{
                float: 'left'
            }}
        /> */}

        {/* <Flex justifyContent="center" px={4} py={5}>
            <Flex width={1} maxWidth="wide" justifyContent="space-between">
                <Box width={[`100%`,`50%`]} px={2}>
                    <Heading as="h4" fontSize={6} mb={3}>Open Source Projects</Heading>
                    <ProjectList>
                        {projects.map(project => (
                            <ProjectListItem
                                tag={project.tags.join(', ')}
                                label={project.title}
                                href={project.url}
                                target="_blank"
                            />
                        ))}
                    </ProjectList>
                </Box>
                <Box width={[`100%`,`50%`]} px={2}>
                    <Heading as="h4" fontSize={6} mb={3}>Recent Client Works</Heading>
                    <ProjectList>
                        {clients.map(client => (
                            <ProjectListItem
                                tag={client.year}
                                label={client.title}
                                href={client.url}
                                rel="nofollow noreferrer noopener"
                                target="_blank"
                            />
                        ))}
                    </ProjectList>
                </Box>
            </Flex>
        </Flex> */}

        {/* <Flex justifyContent="center" px={4} py={5} >
            <Flex width={1} maxWidth="base" justifyContent="space-between">
                <Box width={1}>
                    <p>
                        Interested in finding out more?
                        Looking to extend your network?
                        Looking to extend your team's development capabilities?
                        Full portfolio and work history available upon request.
                        Simply <a href="javascript:;">get in touch</a> and let's get things moving.
                    </p>
                </Box>
            </Flex>
        </Flex> */}

    </Layout>

export default IndexPage
