import * as actions from "../actions/navigation"

const initialState = {
    mobileNavOpen: false,
}

const navigationReducer = (state=initialState, action) => {

    let change = {}

    switch (action.type) {
        case actions.TOGGLE_MOBILE_NAV :
            change.mobileNavOpen = ! state.mobileNavOpen
        break
        // Example code:
        // case actions.DELAYED_DURATION :
        //     change.mobileNavToggleDuration = action.duration
        // break
    }

    return {...state, ...change}
}

export default navigationReducer
