import React, {useEffect} from "react"
// import debounce from "lodash/debounce"
import PropTypes from "prop-types"
import * as scrollActions from "./../store/actions/scroll"
import {connect, Provider as StateProvider} from "react-redux"
import createStore from "../store/create-store"
// import Helmet from "react-helmet"
// import GlobalStyle from "./../theme.css.js"
// import theme from "../theme"
import Navbar from "./navbar"
import {Flex, Box} from "theme-ui"
import Footer from "./Footer"
import "./../styles/global.css"
// import "./../scripts/global.js"

const store = createStore()

const Content = props => {

    const doWindowScroll = e => {
        let lastScrollY = window.scrollY,
            ticking
        if (! ticking) {
            window.requestAnimationFrame(() => {
                props.setFromTop(lastScrollY)
                ticking = false
            })
            ticking = true
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', doWindowScroll, true)
    })

    return (
        <Flex sx={{
                width: '100%',
                minHeight: '100vh',
                flexDirection: 'column',
                backgroundColor: '#fff',
                overflowX: 'hidden',
            }}>
            {props.children}
        </Flex>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setFromTop: fromTop => {
            dispatch(scrollActions.setFromTop(fromTop))
        },
    }
}

const ConnectedContent = connect(null, mapDispatchToProps)(Content)

const Layout = props =>
    <StateProvider store={store}>
        {/* <Helmet>
            <script
                src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
                integrity="sha256-pasqAKBDmFT4eHoN2ndd6lN370kFiGUFyTiUHWhU7k8="
                crossorigin="anonymous"
                // defer
            />
            <script
                src="https://cdn.jsdelivr.net/gh/Aerolab/midnight.js@1.1.2/midnight.jquery.min.js"
                // defer
            />
        </Helmet> */}
        <ConnectedContent>
            <Navbar />
            <Box as="main" sx={{flex: 1}}>
                {props.children}
            </Box>
            <Footer />
        </ConnectedContent>
    </StateProvider>

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
