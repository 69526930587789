/** @jsx jsx */
import {jsx, Box} from "theme-ui"
import {connect} from "react-redux"
import {Link} from "gatsby"
import LogoSvg from "./../svg/mw-logo.svg"

// NOTE: liftIntoMobileNav modifies the z-index.
const Logo = ({
    width,
    liftIntoMobileNav,
}) =>
    <Box
        as={Link}
        to='/'
        sx={{
            position: 'relative',
            maxWidth: width || '86px',
            pointerEvents: 'auto',
            '.mw-logo path': {
                fill: theme => `${theme.colors.primaryDark} !important`,
            },
            // NOTE: New MW logo styles.
            // '#logo-stripe-1': { fill: #3a3a3a, }
            // '#logo-stripe-2': { fill: #bbb, }
            // '#logo-stripe-3': { fill: #3a3a3a, }
            // '#logo-stripe-4': { fill: #bbb, }
            // '#logo-stripe-5': { fill: #3a3a3a, }
            // '#logo-stripe-5-2': {
            //     display: none;
            // }
        }}>
        <LogoSvg />
    </Box>

const mapStateToProps = ({mobileNavOpen}) => {
    return {
        liftIntoMobileNav: mobileNavOpen,
    }
}

const ConnectedLogo = connect(mapStateToProps)(Logo)

export default ConnectedLogo
