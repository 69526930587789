/** @jsx jsx */
import {jsx, useThemeUI, Flex, Box, Text, NavLink, Button} from "theme-ui"
import {useRef, useEffect} from "react"
// import {Link} from "gatsby"
import MailContactLink from "./MailContactLink"
import Container from "./container"
import Logo from "./logo"
// import elsewhere from "../data/elsewhere"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEnvelope} from "@fortawesome/free-solid-svg-icons"

// const ElsewhereIcon = ({
//     children,
//     url,
//     ...rest
// }) =>
//     <Box
//         sx={{
//             display: 'inline-block',
//             pr: 22,
//             pointerEvents: 'none',
//         }}
//         {...rest}>
//         <NavLink
//             href={url}
//             target="_blank"
//             rel="nofollow noreferrer noopener"
//             sx={{
//                 display: 'inline-block',
//                 fontSize: 15,
//                 'svg': {
//                     'path': {
//                         fill: 'accent',
//                         fill: '#fff',
//                     },
//                 },
//             }}>
//             {children}
//         </NavLink>
//     </Box>

const ContactButton = ({
    children,
    ...rest
}) =>
    <Button
        as={MailContactLink}
        sx={{
            position: 'relative',
            display: 'inline-block',
            padding: '7px 20px 6px',
            color: '#fff',
            backgroundColor: 'primary',
            backgroundImage: ({colors}) => `linear-gradient(-90deg, ${colors.primary}, ${colors.primaryDark})`,
            // border: theme => `3px solid ${theme.colors.primary}`,
            borderRadius: '3px',
            fontSize: 'tiny',
            fontWeight: 700,
            textDecoration: 'none',
            overflow: 'hidden',
            backfaceVisibility: 'hidden',
            'span': {
                transition: 'all .4s',
                '&:nth-of-type(1)': {
                    position: 'absolute',
                    top: 0,
                    left: '-100%',
                    left: '-4px',
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                    opacity: 0,
                    'svg': {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        mr: 18,
                    },
                    'div': {
                        position: 'absolute',
                        top: '47.75%',
                        left: '50%',
                        transform: 'translate(-50%, -47.75%)',
                        ml: 18,
                    },
                },
            },
            '&:hover span': {
                '&:nth-of-type(1)': {
                    // left: '-4px',
                    // opacity: 1,
                },
                '&:nth-of-type(2)': {
                    // transform: 'translateX(200%)',
                    // opacity: 0,
                    color: 'shades.4',
                },
            },
        }}
        {...rest}>
        <span>
            <FontAwesomeIcon icon={faEnvelope} />
            <div>→</div>
        </span>
        <Text
            as="span"
            variant="caps"
            sx={{
                display: 'inline-block',
                width: '100%',
                height: '100%',
            }}>
            {children}
        </Text>
    </Button>

const Navbar = (props) => {

    const fromTopOffset = 50,
        navbarRef = useRef(),
        {theme} = useThemeUI()

    useEffect(() => {
        doWindowScroll()
        window.addEventListener('scroll', doWindowScroll, true)
        // window.addEventListener('resize', debounce(doWindowResize, 100))
    })

    const doWindowScroll = e => {
        if (navbarRef.current) {
            if (window.scrollY > fromTopOffset) {
                navbarRef.current.style.position = 'fixed'
                navbarRef.current.style.marginTop = 0
            } else {
                navbarRef.current.style.position = 'absolute'
                navbarRef.current.style.marginTop = ''
            }
        }
    }

    // const doWindowResize = e => {
    //     toggleMobileNav()
    // }

    // const toggleMobileNav = () => {
    //     if (document.body.clientWidth >= parseFloat(theme.breakpoints.lg) && props.mobileNavActive) {
    //         props.toggleMobileNavActive()
    //     }
    // }

    return (
        <Box
            ref={navbarRef}
            as="header"
            sx={{
                position: 'absolute',
                paddingTop: [32, 48],
                zIndex: theme.zIndices.navbar,
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                marginTop: fromTopOffset + 'px',
                width: '100%',
                pointerEvents: 'none',
                // border-bottom: 1px solid ${theme.colors.shades[1]};
            }}>
            <Container
                innerSx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pointerEvents: 'none',
                }}>
                <Logo />
                <Flex
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        '*': {
                            pointerEvents: 'auto',
                        },
                    }}>
                    {/* {elsewhere.map(({url, icon}, index) =>
                        <ElsewhereIcon key={index} url={url}>
                            {icon}
                        </ElsewhereIcon>
                    )} */}
                    <ContactButton>Contact</ContactButton>
                </Flex>
            </Container>
        </Box>
    )
}

export default Navbar
