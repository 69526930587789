/** @jsx jsx */
// import {filter} from "lodash"
import expertise from "./../data/expertise"
import {jsx, Flex, Box, Heading, Text, Container} from 'theme-ui'
import Row from "./Row"
import ContainerHeading from "./ContainerHeading"
import MailContactLink from "./MailContactLink"

const gridSpacingX = [0, -3]
const gridItemSpacingX = [0, 28]

const ExpertiseGrid = ({
    children
}) =>
    <Flex
        sx={{
            flexDirection: ['column', 'row'],
            flexWrap: ['nowrap', 'wrap'],
            // justifyContent: 'center',
            // margin: '0 auto',
            // width: '100%',
            maxWidth: 'sectionMax',
            mx: gridSpacingX,
            mb: [-6],
        }}>
        {children}
    </Flex>

const ExpertiseGridItem = ({
    expertise,
    headingMinHeight,
    ...rest
}) =>
    <Box
        sx={{
            width: ['100%', '50%', `${100/3}%`, '25%'],
            px: gridItemSpacingX,
            mb: [5, 7, 9],
        }}
        {...rest}>
        <Flex sx={{
                alignItems: 'center',
                marginBottom: 3,
                height: headingMinHeight,
            }}>
            <Box sx={{
                    width: expertise.iconStyles.size,
                    fontSize: expertise.iconStyles.size,
                    marginRight: expertise.iconStyles.marginRight,
                    lineHeight: 1,
                    color: expertise.iconStyles.color,
                    '& .gatsby-monogram circle, & .jquery-monogram path, & .backbone-monogram path': {
                        fill: expertise.iconStyles.color
                    },
                    // '& .jquery-monogram path': {
                    //     fill: expertise.iconStyles.color
                    // }
                }}>
                {expertise.icon}
            </Box>
            <Heading
                as="h3"
                sx={{
                    fontSize: 4,
                    lineHeight: 1,
                }}>
                {expertise.title}
            </Heading>
        </Flex>
        <Text
            as="p"
            sx={{
                fontSize: 'small',
            }}>
            {expertise.excerpt}
        </Text>
    </Box>

// const CategoryArrow = props =>
//     <Box
//         sx={{
//             display: 'inline-block',
//             fontSize: 20,
//             fontWeight: 'bold',
//             color: 'heading',
//             mb: 8,
//             transform: 'rotate(-45deg)',
//         }}>
//         ↓
//     </Box>

const IndexServices = props => {

    let iconStyles = {
            size: '24px',
            minHeight: '24px',
            marginRight: '12px',
            color: theme => theme.colors.primary,
        },
        headingMinHeight = iconStyles.size

    // Modify item icon styles.
    expertise.map(expt => {
        expt.iconStyles = {...iconStyles}
        switch (expt.id) {
            case 'js':
                expt.iconStyles.marginRight = '10px'
            break
            case 'php':
                expt.iconStyles.marginRight = '18px'
            break
            case 'gatsby':
                expt.iconStyles.size = '22px'
            break
            case 'next':
                expt.iconStyles.size = '22px'
            break
            case 'laravel':
                expt.iconStyles.size = '26px'
                // NOTE: The "headingMinHeight" must match the biggest icon size.
                headingMinHeight = expt.iconStyles.size
            break
            case 'woo':
                expt.iconStyles.size = '21px'
                expt.iconStyles.marginRight = '15px'
            break
            case 'jquery':
                expt.iconStyles.size = '21px'
                // expt.iconStyles.marginRight = '6px'
            break
            case 'node':
                expt.iconStyles.marginRight = '20px'
            break
        }
        return expt
    })

    return (
        <Row
            id="expertise">
            <Container
                sx={{
                    mb: [4, 0]
                }}>
                <ContainerHeading>Technical Expertise</ContainerHeading>
                <Text
                    sx={{
                        mb: [64, 88]
                    }}>
                    Modern, state-of-the-art JavaScript and PHP know-how.&nbsp;
                    <br sx={{
                        display: ['none', 'inline']
                    }}/>
                    Backed by over 10 years of experience and&nbsp;
                    <br sx={{
                        display: ['none', 'inline']
                    }}/>
                    strong client satisfaction.
                    {/* proven */}
                    {/* These are the tools of my trade. */}
                </Text>
                <ExpertiseGrid>
                    {expertise.map(expt =>
                        <ExpertiseGridItem
                            key={expt.id}
                            expertise={expt}
                            headingMinHeight={headingMinHeight}
                        />
                    )}
                    {/* {filter(expertise, ['category', 'js']).map(expt =>
                        <ExpertiseGridItem
                            key={expt.id}
                            expertise={expt}
                            headingMinHeight={headingMinHeight}
                        />
                    )}
                    {filter(expertise, ['category', 'php']).map(expt =>
                        <ExpertiseGridItem
                            key={expt.id}
                            expertise={expt}
                            headingMinHeight={headingMinHeight}
                        />
                    )} */}
                </ExpertiseGrid>
                <Box
                    sx={{
                        mt: 88,
                    }}>
                    <Text
                        as={MailContactLink}
                        sx={{
                            display: 'inline-block',
                            py: '4px',
                            fontWeight: 'bold',
                            fontSize: ['small'],
                            borderBottom: theme => `4px solid ${theme.colors.primary}`,
                            textDecoration: 'none',
                            color: 'heading',
                            'span': {
                                display: 'inline-block',
                                transition: 'all .4s',
                            },
                            '&:hover': {
                                'span': {
                                    transform: 'translateY(-3px)',
                                },
                            },
                        }}>
                        <Text as="span" variant="caps">Get in touch</Text>
                    </Text>
                    <Text
                        sx={{
                            display: 'inline-block',
                            fontSize: ['tiny', 'small'],
                            ml: [11, 13],
                        }}>
                        to schedule a free consultation.
                    </Text>
                </Box>
            </Container>
        </Row>
    )
}

export default IndexServices
