/** @jsx jsx */
import {jsx, useThemeUI, Flex, Box, Text, Grid, Container} from "theme-ui"
import {useStaticQuery, graphql} from "gatsby"
import Obfuscate from "react-obfuscate"
import Img from "gatsby-image"
import Row from "./Row"
import ParagraphComponent from "./Paragraph"
import ContainerHeading from "./ContainerHeading"
import MailContactLink from "./MailContactLink"
import elsewhere from "../data/elsewhere"

const Paragraph = ({
    children,
    ...rest
}) =>
    <ParagraphComponent
        sx={{
            fontSize: 'small',
            lineHeight: 'wider',
            mb: theme => `${parseFloat(theme.fontSizes.small) * theme.lineHeights.base}px`,
        }}
        {...rest}>
        {children}
    </ParagraphComponent>

const ElsewhereIcon = ({
    children,
    url,
    ...rest
}) =>
    <Text
        as="a"
        href={url}
        target="_blank"
        rel="nofollow noreferrer noopener"
        sx={{
            display: 'inline-block',
            fontSize: 16,
            px: 10,
            'svg': {
                'path': {
                    transition: 'fill .4s',
                    fill: 'heading',
                },
            },
            'span': {
                transition: 'color .4s',
            },
            '&:hover': {
                'svg': {
                    'path': {
                        fill: 'primary',
                    },
                },
                'span': {
                    color: 'primary',
                }
            },
        }}
        {...rest}>
        {children}
    </Text>

const ProfileImage = props => {
    // fluid(grayscale: true)
    // fluid(duotone: {highlight: "#eaecee", shadow: "#000"})
    const {profileimage} = useStaticQuery(graphql`
        query {
            profileimage: file(relativePath: { eq: "images/mw-avatar.jpg" }) {
                childImageSharp {
                    fluid(duotone: {highlight: "#f0f2f4", shadow: "#000000"}, maxHeight: 400, maxWidth: 400) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <Img
            fluid={profileimage.childImageSharp.fluid}
            alt="Marc Wiest portrait"
            sx={{
                borderRadius: 4,
            }}
            {...props}
        />
    )
}

const IndexAbout = () => {

    const {theme} = useThemeUI()

    return (
    <Row
        id="profile"
        sx={{
            ...theme.row.secondary
        }}>
        <Container>
            <ContainerHeading>Profile</ContainerHeading>
            <Grid
                columns={[
                    '100%',
                    null,
                    '1.4fr 1fr',
                    '.33fr 1fr 1fr',
                ]}
                gap={[0, null, 9,]}>
                <Box
                    sx={{
                        display: ['none', null, null, 'block'],
                    }}>
                    <ProfileImage />
                </Box>
                <Box>
                    <Flex>
                        <Box
                            sx={{
                                display: [null, null, null, 'none'],
                                width: ['33%', '25%', '33%'],
                                pr: [3, null, 28],
                                pb: 3,
                            }}>
                            <ProfileImage />
                        </Box>
                        <Box
                            sx={{
                                flex:1
                            }}>
                            <Paragraph>
                                <strong
                                    sx={{
                                        color: 'heading',
                                    }}>
                                    Hi! I'm Marc.
                                </strong>
                            </Paragraph>
                            <Paragraph>
                                I'm a full-stack developer with over 10 years of experience. My focus lies in helping brands and agencies develop tools that help their products and services engage and connect with their users.
                            </Paragraph>
                        </Box>
                    </Flex>
                    <Paragraph>
                        Over the years I have worked with many front and back-end frameworks.&nbsp;
                        {/* My preferred technologies are PHP, JavaScript, jQuery, WordPress, Sass, React, and Backbone. However, nothing is ever set in stone and I'm always happy to adapt to your company's workflow and learn new tricks in the process. */}
                        {/* Talk about: building prosperous relationships with your clients. */}
                        I’ve contracted independently and worked with teams of various sizes. I’m honing my skills on a daily basis and as a passionate programmer, I’m always interested in working out new challenges.
                    </Paragraph>
                    <Paragraph
                        sx={{
                            mb: [null, null, 0],
                        }}>
                        I'm currently residing in Santa Cruz, California and when I'm not working, I usually spend my time hiking in the mountains or relaxing by the ocean with my wife and son.
                    </Paragraph>
                </Box>
                <Box>
                    <Paragraph>
                        <strong
                            sx={{
                                color: 'heading'
                            }}>
                            I'm currently available for contract work.
                        </strong>
                    </Paragraph>
                    <Paragraph>
                        Whether you already have a project in mind or are simply interested in finding out more,
                        feel free to get in touch. I’m currently available, interested in meeting new people,
                        and realizing new exciting projects.
                    </Paragraph>
                    <Paragraph
                        sx={{
                            mb: 0,
                        }}>
                        <Text
                            as={MailContactLink}
                            sx={{
                                display: 'inline-block',
                                py: '4px',
                                fontSize: 'tiny',
                                fontWeight: 'bold',
                                borderBottom: theme => `4px solid ${theme.colors.primary}`,
                                textDecoration: 'none',
                                color: 'heading',
                                'span': {
                                    display: 'inline-block',
                                    transition: 'all .4s',
                                },
                                '&:hover': {
                                    'span': {
                                        transform: 'translateY(-3px)',
                                    },
                                },
                            }}>
                            <Text as="span" variant="caps">Get in touch</Text>
                        </Text>
                        <Flex
                            as="span"
                            sx={{
                                // display: ['flex', null, null, 'none'],
                                flexDirection: ['column', 'row', 'column'],
                                pt: 4,
                            }}>
                            {elsewhere.map(({url, icon, title}, index) =>
                                <Box as="span" key={index} sx={{display: 'block'}}>
                                    <ElsewhereIcon
                                        url={url}
                                        sx={{
                                            py: '6px',
                                            px: 0,
                                            pr: [0, 3, 0],
                                            fontSize: 14,
                                            textDecoration: 'none',
                                        }}>
                                        {icon}
                                        <Text
                                            as="span"
                                            sx={{
                                                fontSize: 'tiny',
                                                fontWeight: 'bold',
                                                color: 'heading',
                                                pl: [12, 1, 12],
                                            }}>
                                            {title}
                                        </Text>
                                    </ElsewhereIcon>
                                </Box>
                            )}
                        </Flex>
                    </Paragraph>
                </Box>
            </Grid>
        </Container>
    </Row>
    )
}

export default IndexAbout
