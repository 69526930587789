import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faGithub, faTwitter, faLinkedin} from "@fortawesome/free-brands-svg-icons"

export default [
    {
        id: 'twitter',
        title: 'Twitter',
        icon: <FontAwesomeIcon icon={faTwitter} />,
        url: 'https://twitter.com/messages/compose?recipient_id=21381427',
    },
    {
        id: 'github',
        title: 'Github',
        icon: <FontAwesomeIcon icon={faGithub} />,
        url: 'https://github.com/marcwiest/',
    },
    // TODO: Add sourcerer profile here.
    {
        id: 'linkedin',
        title: 'Linkedin',
        icon: <FontAwesomeIcon icon={faLinkedin} />,
        url: 'https://www.linkedin.com/in/marcwiest/',
    },
    // {
    //     id: 'stackoverflow',
    //     icon: <FontAwesomeIcon icon={faStackOverflow} />,
    //     url: 'https://stackoverflow.com/users/830992/marc-wiest',
    // },
]
