import {createStore as reduxCreateStore, combineReducers, applyMiddleware, compose} from "redux"
import navigationReducer from "./reducers/navigation"
import scrollReducer from "./reducers/scroll"
import thunk from "redux-thunk"

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const rootReducer = combineReducers({
    nav: navigationReducer,
    scroll: scrollReducer,
})

const createStore = () => reduxCreateStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(thunk)
    )
)

export default createStore
