/** @jsx jsx */
import {jsx} from "theme-ui"
import Obfuscate from "react-obfuscate"

const MailContactLink = props =>
    <Obfuscate
        target="_blank"
        email="marc@marcwiest.com"
        headers={{
            subject: "Service Inquiry",
            body: "Hi Marc, \r\nmy name is ___ and I'm interested in ___. \r\nYou can reach me via ___ Monday through Friday. \r\nThanks",
        }}
        {...props}
    />

export default MailContactLink
