import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    faPhp,
    faJsSquare,
    faWordpressSimple,
    faReact,
    faLaravel,
    faNode,
    // faVuejs,
    faCss3,
    faSass,
} from "@fortawesome/free-brands-svg-icons"
// import * as fa from "@fortawesome/free-solid-svg-icons"
import {
    faShoppingBasket,
    faPlayCircle
} from "@fortawesome/free-solid-svg-icons"
import GatsbyMonogram from "./../svg/gatsby-monogram.svg"
import JQueryMonogram from "./../svg/jquery-monogram.svg"
import NextMonogram from "./../svg/next-monogram.svg"
// import BackboneMonogram from "./../svg/backbone-monogram.svg"


export default [
    {
        id: 'js',
        category: 'js',
        title: 'JavaScript',
        icon: <FontAwesomeIcon icon={faJsSquare} />,
        excerpt: `JavaScript is the primary language used for modern web development. Many frameworks exist but knowing bare bones JS is still paramount today.`,
    },
    {
        id: 'php',
        category: 'php',
        title: 'PHP',
        icon: <FontAwesomeIcon icon={faPhp} />,
        excerpt: `PHP is an established and highly available server side language. PHP powers most content management systems and is still growing in popularity.`,
    },
    {
        id: 'react',
        category: 'js',
        title: 'React',
        icon: <FontAwesomeIcon icon={faReact} />,
        excerpt: `React is the technology of choice when it comes to building high-performance applications. React is fast, scalable, and easy to maintain.`,
    },
    {
        id: 'next',
        category: 'js',
        title: 'Next',
        icon: <FontAwesomeIcon icon={faPlayCircle} rotation={270} />,
        excerpt: `Next is an open-source React framework that helps developers build sites for both, server-side rendering or client-side rendering.`,
    },
    {
        id: 'gatsby',
        category: 'js',
        title: 'Gatsby',
        icon: <GatsbyMonogram />,
        excerpt: `Gatsby is an open-source framework based on React that helps developers build blazing fast websites and apps.`,
    },
    {
        id: 'wp',
        category: 'php',
        title: 'WordPress',
        icon: <FontAwesomeIcon icon={faWordpressSimple} />,
        excerpt: `WordPress is the platform of choice for over 35% of all sites across the web. WordPress is open-source and provides the freedom to do whatever you want.`,
    },
    {
        id: 'woo',
        category: 'php',
        title: 'WooCommerce',
        icon: <FontAwesomeIcon icon={faShoppingBasket} />,
        excerpt: `WooCommerce is a flexible, open-source ecommerce solution built on WordPress. It's a completely customizable and can be used for anything you can imagine.`,
    },
    {
        id: 'laravel',
        category: 'php',
        title: 'Laravel',
        icon: <FontAwesomeIcon icon={faLaravel} />,
        excerpt: `Laravel is an open-source framework for PHP. It provides a solid MVC foundation, that's freeing you to create without sweating the small things.`,
    },
    {
        id: 'node',
        category: 'js',
        title: 'Node',
        icon: <FontAwesomeIcon icon={faNode} />,
        excerpt: `Node is an asynchronous event-driven JavaScript runtime, it's designed to build scalable network applications.`,
    },
    {
        id: 'css',
        category: 'css',
        title: 'CSS',
        icon: <FontAwesomeIcon icon={faCss3} />,
        excerpt: `CSS enables the separation of presentation and content, including layout, colors, and fonts.`,
        // Processors like Sass, Less, Stylus, and PostCSS enable many new tricks.
    },
    {
        id: 'sass',
        category: 'css',
        title: 'Sass',
        icon: <FontAwesomeIcon icon={faSass} />,
        excerpt: `Sass is the most mature, stable, and powerful professional grade CSS extension language in the world.`,
    },
    {
        id: 'jquery',
        category: 'js',
        title: 'jQuery',
        icon: <JQueryMonogram />,
        excerpt: `jQuery has long been the standard for normalizing browser inconsistencies, simplifying events and HTML DOM manipulation in JavaScript.`,
    },
    // {
    //     id: 'backbone',
    //     category: 'js',
    //     title: 'Backbone',
    //     // <a href="https://iconscout.com/icons/backbonejs" target="_blank">Backbonejs Icon</a> by <a href="https://iconscout.com/contributors/icon-mafia" target="_blank">Icon Mafia</a>
    //     icon: <BackboneMonogram />,
    //     excerpt: `Backbone provides a minimalistic foundation for data-rich web applications with ambitious interfaces.`,
    // },
    // {
    //     id: 'vue',
    //     category: 'js',
    //     title: 'Vue',
    //     icon: <FontAwesomeIcon icon={faVuejs} />,
    //     excerpt: `Vue is a progressive framework for building user interfaces with primary focus on the view layer.`,
    // },
    // {
    //     id: 'gutenberg',
    //     category: 'js',
    //     title: 'Gutenberg',
    //     icon: <FontAwesomeIcon icon={faVuejs} />,
    //     excerpt: ``,
    // },
    // {
    //     id: 'frontity',
    //     category: 'js',
    //     title: 'Frontity',
    //     icon: <FontAwesomeIcon icon={faVuejs} />,
    //     excerpt: `https://frontity.org/`,
    // },
    // {
    //     id: 'webpack',
    //     category: 'js',
    //     title: 'Webpack',
    //     icon: <FontAwesomeIcon icon={faVuejs} />,
    //     excerpt: ``,
    // },
    // {
    //     id: 'gulp',
    //     category: 'js',
    //     title: 'Gulp',
    //     icon: <FontAwesomeIcon icon={faVuejs} />,
    //     excerpt: ``,
    // },
]
