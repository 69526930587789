/** @jsx jsx */
import React, {useEffect, useRef} from "react"
import {kebabCase, toLower, uniqBy, sortBy} from "lodash"
import {jsx, Flex, Box, Text} from "theme-ui"
import Img from "gatsby-image"
import AspectRatio from "./AspectRatio"
// NOTE: Since Isotope uses the window object, Isotop needs to be imported via React's `useEffect` hook.
// import MetafizzyIsotope from "isotope-layout"

const itemWidth = ['100%', '50%', `${100/3}%`, '25%'],
itemRatio = [4/2.33, 3/2.33],
itemPadding = 24,
isotopeSpeed = 650

const Nav = ({
    children,
}) =>
    <Box as="ul">
        {children}
    </Box>

const NavItem = ({
    children,
    isActive,
    filter,
    ...rest
}) =>
    <Box
        as="li"
        sx={{
            position: 'relative',
            ml: -1,
        }}
        {...rest}>
        <Text
            as="a"
            href="#"
            className={`isotope-grid-filter ${isActive && 'is-active'}`}
            data-filter={filter}
            variant="caps"
            sx={{
                display: 'inline-block',
                position: 'relative',
                left: '-20px',
                py: 1,
                px: 1,
                fontSize: 'tiny',
                fontWeight: 'bold',
                textDecoration: 'none',
                color: '#fff',
                transition: 'all .55s',
                '&.is-active': {
                    left: 0,
                },
            }}>
            <Text
                as="span"
                sx={{
                    pr: '4px',
                    opacity: 0,
                    color: 'primary',
                    fontWeight: 'bold',
                    transition: 'all .35s .1s',
                    '.isotope-grid-filter.is-active &': {
                        opacity: 1,
                    }
                }}>→ </Text>
            {children}
        </Text>
    </Box>

const IsotopeNavItem = ({
    items,
}) =>
    <Flex
        as="nav"
        className="isotope-grid-filters"
        sx={{
            display: ['none', 'flex'],
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: itemWidth,
        }}>
        <AspectRatio
            ratio={itemRatio}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bg: '#1a1b1c',
            }}>
            <Nav>
                <NavItem isActive={true} filter="*">All Projects</NavItem>
                {sortBy(uniqBy(items, 'filterSlug'), ['primaryTag']).map((item, index) =>
                    <NavItem key={index} filter={item.filterSlug}>{item.primaryTag}</NavItem>
                )}
            </Nav>
        </AspectRatio>
    </Flex>

const IsotopeItem = ({
    item, /* = {
        published: true,
        title: "Vamonos",
        date: "2018-08-01",
        tags: ["Client Work"],
        primaryTag: "Client Work",
        filterSlug: "client-work",
        slug: "vamonos",
        collaborators: ["what.it.is"],
        externalUrl: "http://vamonos-texmex.com/",
        previewImage: {childImageSharp: {…}},
    } */
    ...rest
}) =>
    <Flex
        as="a"
        target="_blank"
        rel="nofollow noreferrer noopener"
        href={item.externalUrl}
        className={`isotope-grid-item ${item.filterSlug}`}
        sx={{
            width: itemWidth,
            mb: [2, 0],
            '.isotope-grid-item-bg-image': {
                transition: 'all .8s',
            },
            '&:hover': {
                '.isotope-grid-item-bg-image': {
                    transform: 'scale(1.033)',
                },
            },
        }}
        {...rest}>
        <AspectRatio
            ratio={itemRatio}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                p: itemPadding,
            }}>
            <Box
                className="isotope-grid-item-bg-image"
                sx={{
                    '&, &:after': {
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                    '&:after': {
                        content: '""',
                        backgroundImage: 'linear-gradient(0deg, rgba(0,0,0,.65), rgba(0,0,0,.20))',
                    }
                }}>
                <Img
                    fluid={item.previewImage.childImageSharp.fluid}
                    alt={`${item.title} preview`}
                    sx={{
                        top: '50%',
                        transform: 'translateY(-50%)',
                    }}
                />
            </Box>
            <Box
                sx={{
                    position: 'relative',
                    bottom: -(itemPadding * 1),
                    transition: 'all .5s',
                    '.isotope-grid-item:hover &': {
                        bottom: 0,
                    }
                }}>
                <Text
                    variant="caps"
                    sx={{
                        position: 'relative',
                        lineHeight: 'tightest',
                        fontSize: 'tiny',
                        fontWeight: 'bold',
                        color: 'white',
                    }}>
                    {item.title}
                </Text>
                <Text
                    sx={{
                        position: 'relative',
                        pt: 12,
                        opacity: 0,
                        lineHeight: 1,
                        fontSize: 'tiny',
                        color: 'white',
                        transition: 'all .3s .1s',
                        '.isotope-grid-item:hover &': {
                            opacity: 1,
                        }
                    }}>
                    Visit →
                </Text>
            </Box>
        </AspectRatio>
    </Flex>

const Isotope = ({
    items,
}) => {

    const isotopeRef = useRef()

    // Normalize items.
    items = items.map(item => {
        let defaults = {
            published: true,
            title: '',
            date: '',
            tags: [],
            primaryTag: '',
            filterSlug: '',
        }
        item = {...defaults, ...item}
        item.primaryTag = item.tags[0] || ''
        item.filterSlug = toLower(kebabCase(item.primaryTag))
        return item
    })

    function initIsotope() {

        // if (typeof window === 'undefined') {
        //     return
        // }

        const MetafizzyIsotope = require('isotope-layout')

        const isotope = new MetafizzyIsotope(isotopeRef.current, {
            stamp: '.isotope-grid-filters',
            itemSelector: '.isotope-grid-item',
            layoutMode: 'masonry',
            percentPosition: true,
            stagger: 30,
            transitionDuration: isotopeSpeed,
            containerStyle: {
                position: 'relative',
                transition: `height ${isotopeSpeed}ms`,
            },
            hiddenStyle: {
                opacity: 0,
                transform: 'scale(0.001)'
            },
            visibleStyle: {
                opacity: 1,
                transform: 'scale(1)'
            }
        })

        const navItems = isotopeRef.current.querySelectorAll('.isotope-grid-filter')
        let activeNavItemNode

        navItems.forEach(node => {

            // Add event listener to animate filter nav items.
            node.addEventListener('mouseenter', e => {
                navItems.forEach(node => {
                    if (node.classList.contains('is-active')) {
                        activeNavItemNode = node
                        node.classList.remove('is-active')
                    }
                })
                e.currentTarget.classList.add('is-active')
            })
            node.addEventListener('mouseleave', e => {
                navItems.forEach(node => node.classList.remove('is-active'))
                activeNavItemNode.classList.add('is-active')
            })

            // Add event listeners to filter isotope items.
            node.addEventListener('click', e => {
                e.preventDefault()
                activeNavItemNode = e.currentTarget
                // Remove & add `is-active` class name.
                navItems.forEach(node => node.classList.remove('is-active'))
                activeNavItemNode.classList.add('is-active')
                // Filter isotope items.
                let filter = activeNavItemNode.getAttribute('data-filter')
                isotope.arrange({
                    filter: gridItem => filter === '*' || gridItem.classList.contains(filter)
                })
            })
        })
    }
    useEffect(() => initIsotope(), [])

    return (
        <Box
            className="isotope-grid"
            ref={isotopeRef}
            sx={{
                bg: 'shades.0',
            }}>
            <IsotopeNavItem items={items} />
            {items.map((item, index) =>
                item.published && <IsotopeItem item={item} key={index} />
            )}
        </Box>
    )
}

export default Isotope
