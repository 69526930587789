/** @jsx jsx */
import {useRef, useEffect} from "react"
import {connect} from "react-redux"
import {useStaticQuery, graphql} from "gatsby"
import {jsx, Flex, Box, Text, Heading} from 'theme-ui'
import Container from "./container"
import Img from "gatsby-image"
import ScrollLink from "./ScrollLink"

// Add static height to hero image to prevent jumping on mobile due to searchbar.
const setStaticHeroHeight = ref => {

    // https://stackoverflow.com/a/14283643/830992
    let isTouchDevice = function() {  return 'ontouchstart' in window || 'onmsgesturechange' in window; }
    // let isDesktop = window.screenX != 0 && !isTouchDevice() ? true : false

    if (! isTouchDevice) {
        return;
    }

    // Get the dynamic height.
    let height = ref.current.offsetHeight

    let heroContent = ref.current.querySelector('.hero-content'),
        gatsbyImageWrapper = ref.current.querySelector('.gatsby-image-wrapper')

    // Remove previously set static height.
    ref.current.style.height = ''

    // Set the static height.
    ref.current.style.height = `${height}px`
    heroContent && (heroContent.style.height = `${height}px`)
    gatsbyImageWrapper && (gatsbyImageWrapper.style.height = `${height}px`)
}

const HeroCta = ({
    children,
    ...rest
}) =>
    <ScrollLink
        sx={{
            textTransform: 'uppercase',
            fontSize: [15, 22],
            fontWeight: 'bold',
            color: '#fff',
            borderBottom: theme => `5px solid ${theme.colors.primary}`,
            paddingBottom: '4px',
            transition: 'all .4s',
            '&:not(:last-child)': {
                marginRight: [18, 32],
            },
            'span': {
                display: 'inline-block',
                transition: 'all .4s',
            },
            '&:hover': {
                'span': {
                    transform: 'translateY(-4px)',
                },
                // color: 'primary',
                // borderBottomColor: 'primary',
            },
        }}
        {...rest}>
        <Text as="span" variant="caps">{children}</Text>
    </ScrollLink>

const BgImg = props => {

    const imgRef = useRef()

    const query = useStaticQuery(graphql`{
        file(relativePath: { eq: "images/poster-desk.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }`)

    const doWindowScroll = e => {
        // document.body.clientWidth
        // imgRef.current.style.top = `-${window.scrollY / 4}px`
    }

    const doWindowResize = e => {
        setStaticHeroHeight(imgRef)
    }

    useEffect(() => {
        setStaticHeroHeight(imgRef)
        window.addEventListener('scroll', doWindowScroll, true)
        window.addEventListener('resize', doWindowResize, true)
    })

    return (
        <Box
            ref={imgRef}
            sx={{
                position: 'fixed',
                height: '100%',
                width: '100%',
                // transition: 'height 1s',
            }}>
            <Img
                fluid={query.file.childImageSharp.fluid}
                alt="Web developer"
                sx={{
                    height: '100%',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundImage: 'linear-gradient(180deg, rgba(0,0,0,.65), rgba(0,0,0,.35))',
                    },
                }}
            />
        </Box>
    )
}

const IndexHero = props => {

    const heroRef = useRef()

    const doWindowResize = e => {
        setStaticHeroHeight(heroRef)
    }

    useEffect(() => {
        setStaticHeroHeight(heroRef)
        window.addEventListener('resize', doWindowResize, true)
    })

    return (
        <Box
            ref={heroRef}
            sx={{
                position: 'relative',
                minHeight: '100vh',
                // transition: 'height 1s',
            }}>
            <BgImg />
            <Container
                className="hero-content"
                innerSx={{
                    flexDirection: 'column',
                    minHeight: '100vh',
                    pb: [60, null, 90, 200],
                }}>
                <Heading
                    as="h1"
                    sx={{
                        maxWidth: [400, 540, null, 700],
                        mt: [190, 240, 240],
                        mb: [50, null, 90],
                        fontSize: [3, 2, 1],
                        lineHeight: 'tight',
                        color: '#fff',
                    }}>
                    {/* Software Development */}
                    {/* Web Development Services for Brands & Agencies */}
                    Web Design & Development for Brands & Agencies
                </Heading>
                <Flex>
                    <HeroCta href="#projects">Projects</HeroCta>
                    <HeroCta href="#expertise">Expertise</HeroCta>
                    <HeroCta href="#profile">Profile</HeroCta>
                </Flex>
            </Container>
        </Box>
    )
}

let mapStateToProps = (state) => {
    return {
        fromTop: state.scroll.fromTop,
    }
}

export default connect(mapStateToProps)(IndexHero)
