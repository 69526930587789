/** @jsx jsx */
import {jsx, Text, Box, Grid} from "theme-ui"
import {Link} from "gatsby"
import Container from "./container"
import ScrollLink from "./ScrollLink"

const FooterLink = ({
    children,
    ...rest
}) =>
    <Link
        sx={{
            display: 'inline-block',
            ml: 1,
            color: 'primaryDark',
            textDecoration: 'none',
            '&:hover': {
                color: 'text',
            },
        }}
        {...rest}>
        {children}
    </Link>

const Footer = () =>
    <Container
        outerSx={{
            bg: '#fff',
            pt: 6,
            pb: 6,
        }}>
        <Grid
            columns={['1fr auto']}
            gap={2}
            sx={{
                width: '100%',
            }}>
            <Box>
                <Text
                    sx={{
                        fontSize: 12,
                    }}>
                    &copy; {new Date().getFullYear()} Marc Wiest, all rights reserved.
                    {/* <FooterLink to="/terms">Terms</FooterLink> */}
                    {/* <FooterLink to="/privacy">Privacy</FooterLink> */}
                </Text>
            </Box>
            <ScrollLink
                href="#top"
                sx={{
                    fontSize: 'tiny',
                    textAlign: 'right',
                    fontWeight: 'bold',
                    color: 'primary',
                    'span': {
                        display: 'inline-block',
                        transition: 'all .4s',
                    },
                    '&:hover': {
                        'span': {
                            transform: 'translatey(-4px)',
                        },
                    },
                }}>
                <span>↑</span>
            </ScrollLink>
        </Grid>
    </Container>

export default Footer
