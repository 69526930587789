/** @jsx jsx */
import {jsx, Link} from "theme-ui"

const ScrollLink = ({
    offset,
    ...rest
}) => {

    const scrollIntoView = e => {
        e.preventDefault()

        const hash = e.currentTarget.hash,
            elem = hash.length < 2 ? null : document.querySelector(hash)

        if (elem !== null) {
            offset = elem.offsetTop + (offset || 0)
        } else if (hash === '#top') {
            offset = 0
        } else {
            return
        }

        // NOTE: Learn more options at: https://css-tricks.com/snippets/jquery/smooth-scrolling/
        window.scroll({
            top: offset,
            left: 0,
            behavior: 'smooth'
        })
    }

    return (
        <Link
            href="#top"
            onClick={scrollIntoView}
            {...rest}
        />
    )
}

export default ScrollLink
