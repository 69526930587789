export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV'
export const DELAYED_DURATION = 'DELAYED_DURATION'

export const toggleMobileNav = () => {
    return {
        type: TOGGLE_MOBILE_NAV
    }
}

// Example codes:

export const delayedDuration = (duration) => {
    return {
        type: DELAYED_DURATION,
        duration: duration,
    }
}

export const delayedDurationAsync = (duration) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(delayedDuration(duration))
        }, duration);
    }
}
