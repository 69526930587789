/** @jsx jsx */
import {jsx, Flex} from 'theme-ui'

const Container = ({
    children,
    outerSx,
    innerSx,
    ...rest
}) =>
    <Flex
        sx={{
            // NOTE: Position relative is needed to overlap the parallax hero image.
            position: 'relative',
            justifyContent: 'center',
            width: '100%',
            px: [2, 2, 6, 8],
            ...outerSx,
        }} {...rest}>
        <Flex
            sx={{
                width: '100%',
                maxWidth: '1400px',
                ...innerSx
            }}>
            {children}
        </Flex>
    </Flex>

export default Container
