/** @jsx jsx */
import {jsx, Container} from "theme-ui"
import {useStaticQuery, graphql} from "gatsby"
import Row from "./Row"
import ContainerHeading from "./ContainerHeading"
import Isotope from "./isotope"

const IndexProjects = props => {

    const {projects} = useStaticQuery(graphql`
        query {
            projects: allMdx(
                filter: { fields: {contentType: { eq: "project" } } }
                sort: { order: DESC, fields: [frontmatter___date] }
            ) {
                edges {
                    node {
                        fields {
                            slug
                            relativeDirectory
                        }
                        frontmatter {
                            title
                            date
                            tags
                            published
                            collaborators
                            externalUrl
                            previewImage {
                                childImageSharp {
                                    fluid( maxWidth: 600, maxHeight: 600, cropFocus: CENTER ) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <Row
            id="projects"
            sx={{
                bg: '#fff',
            }}>
            <Container>
                <ContainerHeading>Notable Work</ContainerHeading>
                <Isotope
                    items={projects.edges.map(({node}) => ({
                        slug: node.fields.slug,
                        ...node.frontmatter,
                    }))}
                />
            </Container>
        </Row>
    )
}

export default IndexProjects
