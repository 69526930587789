/** @jsx jsx */
import {jsx, Box} from 'theme-ui'

const Row = ({
    children,
    ...rest
}) =>
    <Box
        sx={{
            // NOTE: Position relative is needed to overlap the parallax hero image.
            position: 'relative',
            width: '100%',
            px: [2, 4, 6, 8],
            pt: [80, 100, 140],
            pb: [80, 100, 140],
            bg: '#fff',
        }}
        {...rest}>
        {children}
    </Box>

export default Row
