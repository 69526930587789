/** @jsx jsx */
import {jsx, Text} from "theme-ui"

const Paragraph = ({
    children,
    ...rest
}) =>
    <Text
        as="p"
        variant="paragraph"
        {...rest}>
        {children}
    </Text>

export default Paragraph
