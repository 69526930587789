/** @jsx jsx */
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import {useStaticQuery, graphql} from "gatsby"
// import Img from "gatsby-image"
import {jsx, useThemeUI, Flex, Box, Text, Grid, Container} from "theme-ui"
import Row from "./Row"
import ContainerHeading from "./ContainerHeading"

const Dash = props =>
    <Box
        sx={{
            height: '4px',
            width: 22,
            mr: [2, 3, 4],
            mt: 10,
            bg: 'primary',
        }}
        {...props}
    />

const Recommendations = ({
    items,
}) => {

    return items.map((item, index) => {
        return item.published && (
            <Flex
                key={index}
                sx={{
                    pb: [4],
                }}>
                <Dash
                    sx={{
                        display: 'none',
                        '@media screen and (min-width:480px)': {
                            display: 'inline-block',
                        },
                    }}
                />
                <Flex
                    sx={{
                        flexDirection: 'column',
                        flex: 1,
                    }}>
                    <Text
                        variant="caps"
                        sx={{
                            color: 'heading',
                            fontWeight: 'heading',
                            fontSize: ['tiny', 'small'],
                            mb: 14,
                        }}>
                        <Dash
                            sx={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                mt: '-2px',
                                '@media screen and (min-width:480px)': {
                                    display: 'none',
                                },
                            }}
                        />
                        {item.brandName}
                    </Text>
                    <Text
                        sx={{
                            fontSize: 'small',
                            mb: 14,
                        }}>
                        <MDXRenderer>{item.body}</MDXRenderer>
                    </Text>
                    <Box>
                        <Text
                            variant="heading"
                            sx={{
                                display: 'inline-block',
                                fontSize: 'small',
                                mb: '6px',
                            }}>
                            {item.author}
                        </Text>
                        <span sx={{
                                fontSize: 'small',
                                fontStyle: 'italic',
                                px: '5px',
                            }}>
                            -
                        </span>
                        <Text
                            sx={{
                                display: 'inline-block',
                                fontSize: 'tiny',
                                fontStyle: 'italic',
                                // pl: '4px',
                            }}>
                            {item.authorRole}
                        </Text>
                    </Box>
                </Flex>
            </Flex>
        )
    })
}

const IndexRecommendations = props => {

    const {theme} = useThemeUI()

    const {recommendations} = useStaticQuery(graphql`
        query {
            recommendations: allMdx(
                filter: { fields: { contentType: { eq: "recommendation" } } }
                sort: { fields: frontmatter___datePublished, order: DESC }
            ) {
                edges {
                    node {
                        body
                        frontmatter {
                            published
                            author
                            authorRole
                            brandName
                            brandUrl
                            brandUrlActive
                            datePublished
                        }
                    }
                }
            }
        }
    `)

    return (
        <Row
            id="recommendations"
            sx={{
                ...theme.row.secondary
            }}>
            <Container>
                <ContainerHeading>Recommendations</ContainerHeading>
                {/* <Text
                    sx={{
                        mb: [64, 88]
                    }}>
                    Kind words from great company.
                </Text> */}
                <Grid
                    columns={[1, null, 2]}
                    gap={[3, 3, 4, 5]}
                    sx={{
                        mb: [-1, 0]
                    }}>
                    <Recommendations
                        items={recommendations.edges.map(reco => {
                            let data = reco.node.frontmatter
                            data.body = reco.node.body
                            return data
                        })}
                    />
                </Grid>
            </Container>
        </Row>
    )
}
export default IndexRecommendations
